// AuthContext.ts
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut, User, UserCredential } from 'firebase/auth'
import React, { createContext, useContext, useEffect, useState } from 'react'

import app from './firebase.service' // Import the Firebase app

interface AuthContextType {
    currentUser: User | null
    logIn: (email: string, password: string) => Promise<UserCredential>
    logOut: () => Promise<void>
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export const useAuth = () => {
    const context = useContext(AuthContext)
    if (!context) {
        throw new Error('useAuth must be used within an AuthContextProvider')
    }
    return context
}

export const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [currentUser, setCurrentUser] = useState<User | null>(null)
    const [loading, setLoading] = useState(true)

    const auth = getAuth(app)

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                user.getIdToken().then((token) => {
                    localStorage.setItem('token', token)
                })
                setCurrentUser(user)
            } else {
                setCurrentUser(null)
                localStorage.setItem('token', '')
            }
            setLoading(false)
        })

        return () => unsubscribe()
    }, [auth])

    const logIn = (email: string, password: string) => {
        return signInWithEmailAndPassword(auth, email, password)
    }

    const logOut = async () => {
        setCurrentUser(null)
        localStorage.setItem('token', '')
        await signOut(auth)
    }

    const contextValue: AuthContextType = {
        currentUser,
        logIn,
        logOut,
    }

    return <AuthContext.Provider value={contextValue}>{loading ? null : children}</AuthContext.Provider>
}
